import './Navigation.scss'

// Atoms
import Logo from '../../../ruya-shared/shared/ui/atoms/logo/Logo'

// Molecules
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'

// Organisms
import NavigationMobile from '../navigationMobile/NavigationMobile'
import NavigationSettings from '../navigationSettings/NavigationSettings'

// Settings
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'

// Store
import useLanguageStore from '../../../store/languageStore'
import useNavigationStore from '../../../store/navigationStore'
import useUserStore from '../../../store/userStore'

// Language
import { useTranslation } from 'react-i18next'

const Navigation = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Store
	const navigationStore = useNavigationStore()
	const languageStore = useLanguageStore()
	const userStore = useUserStore()

	const isUserLoggedIn = userStore.user !== null

	// NextJS language path
	const languagePath =
		languageStore.selectedLanguage?.isoCode === languageSettings.defaultLanguage ? '' : `/${languageStore.selectedLanguage?.isoCode}`

	return (
		<div className="Navigation">
			<Logo />
			<div className="Navigation_Middle">
				<ul className="Navigation_Middle_Links">
					<li>
						<ReactLink className="MainNavButton" href={`${commonSettings.apps.next.url}${languagePath}`} isInternal={true}>
							{t('navigation:home')}
						</ReactLink>
					</li>
					<li>
						<ReactLink
							className="MainNavButton"
							href={`${commonSettings.apps.next.url}${languagePath}/articles`}
							isInternal={true}>
							{t('navigation:articles')}
						</ReactLink>
					</li>
					{navigationStore.headerNavigation?.map((link: any) => (
						<li key={`Header_${link._id}`}>
							<ReactLink
								className="MainNavButton"
								href={`${commonSettings.apps.next.url}${languagePath}/pages/${link.slug}`}
								isInternal={true}>
								{link.linkLabel}
							</ReactLink>
						</li>
					))}
					{isUserLoggedIn && (
						<li>
							<ReactLink className="MainNavButton" href={`${commonSettings.apps.web.paths.journal}`}>
								{t('navigation:journal')}
							</ReactLink>
						</li>
					)}
					{!isUserLoggedIn && (
						<li>
							<ReactLink className="MainNavButton" href={`${commonSettings.apps.web.paths.login}`}>
								{t('navigation:login')}
							</ReactLink>
						</li>
					)}
				</ul>
			</div>
			<div className="Navigation_End">
				<ul className="Navigation_End_Links">
					{!isUserLoggedIn && (
						<>
							<li>
								<ReactLink className="LanguageButton" href={`${commonSettings.apps.web.paths.language}`}>
									{languageSettings.showCountryFlag && (
										<span
											className="LanguageButton_Flag"
											style={{
												backgroundImage: `url(${commonSettings.apps.cdn.url}/assets/flags/4x3/${languageStore.selectedLanguage?.flag})`
											}}
										/>
									)}
									{languageStore.selectedLanguage?.displayName}
								</ReactLink>
							</li>
							<li>
								<ReactLink className="SignUpButton" href={`${commonSettings.apps.web.paths.signUp}`}>
									{t('navigation:signupForFree')}
								</ReactLink>
							</li>
						</>
					)}
				</ul>
				<NavigationSettings />
				<NavigationMobile />
			</div>
		</div>
	)
}

export default Navigation
